<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card" v-if="loading">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="spinner-border m-5" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="!loading && !success">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center my-5">
          <p>No Resource Found. Try Again</p>
        </div>
      </div>
    </div>

    <div class="row " v-if="!loading && success">
      <div class="col-md-12 mb-4">
        <h4 class="card-title my-3 float-right">
          <router-link
            :to="`/user/${$route.params.id}/courses`"
            class="btn btn-success btn-sm"
            >Courses</router-link
          >
        </h4>
      </div>
    </div>
    <div
      class="container card content_margin_bottom pt-4"
      v-if="!loading && success"
    >
      <div class="table-wrapper">
        <table class="table table-borderless">
          <thead>
            <tr>
              <td scope="col"><b>Instructor</b></td>
              <td scope="col">{{ course.entity.name }}</td>
            </tr>
            <tr>
              <td scope="col"><b>About</b></td>
              <td scope="col">{{ course.entity.about }}</td>
            </tr>
            <tr>
              <td scope="col"><b>Course Title</b></td>
              <td scope="col">{{ course.title }}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Course Overview</b></td>
              <td>{{ course.overview }}</td>
            </tr>
            <tr>
              <td><b>Number of Students Enrolled</b></td>
              <td>{{ course.enrolled }}</td>
            </tr>
            <tr>
              <td><b>Course Catalogue</b></td>
              <td>{{ course.show_in_catalogue }}</td>
            </tr>
          </tbody>
        </table>
        <button
          @click="enroll"
          :disabled="enrolledIds.includes(course.id)"
          class="btn btn-success btn-sm float-right my-5"
        >
          {{ enrolledIds.includes(course.id) ? "Enrolled" : "Enroll" }}
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import toasterMixin from "@/mixins/toasterMixin.js";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import store from "@/state/store";

export default {
  name: "Course Details",
  components: {
    Layout,
    PageHeader,
  },
  page: {
    title: "Course Details",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Course Details",
      items: [
        {
          text: "User",
        },
        {
          text: "Course Details",
          active: true,
        },
      ],
      course: {},
      enrolledCourses: [],
      userId: this.$route.params.id,
      courseId: this.$route.params.courseId,
      loading: false,
      success: false,
    };
  },
  computed: {
    enrolledIds() {
      if (!this.enrolledCourses || this.enrolledCourses.length == 0) return [];

      const ids = this.enrolledCourses.map((course) => course.course_id);
      return ids;
    },
  },
  methods: {
    editCustomer() {
      this.$router.push(
        `/entity/${this.$route.params.id}/course/${this.$route.params.courseId}/edit`
      );
    },
    enroll() {
      const data = {
        courseId: this.courseId,
        individualId: this.userId,
      };
      this.$store
        .dispatch("enroll/enrollCourse", data)
        .then((response) => {
          this.toast({ message: response.data.msg, type: "success" });
          console.log(response);
        })
        .catch((err) => {
          this.requestError = "An error occured";
        });
    },
    fetchData() {
      this.loading = true;
      store
        .dispatch("enroll/fetchAllCourses")
        .then((response) => {
          const courses = response.data.course;
          const course = courses.find(
            (course) => course.id == this.$route.params.courseId
          );
          this.course = course;
          console.log(this.course);
          this.loading = false;
          this.success = true;
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });

      this.$store
        .dispatch("enroll/myCourses", this.userId)
        .then((response) => {
          this.enrolledCourses = [...response.data.Enrollments];
          console.log("mycourses", this.enrolledCourses);
        })
        .catch((err) => {
          if (err?.response?.data === undefined)
            this.requestError = "An error occured";
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
b {
  font-weight: bolder;
}
.table-wrapper {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
</style>
